<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card" style="width: 100%;">
                    <div class="card-header">
                        {{ $t('frontend.page-start.component_headline') }}
                    </div>
                    <div class="card-body">
                        {{ $t('frontend.page-start.component_text') }}

                        <div class="form-group" style="margin-top: 32px;">
                            <a href="/login" class="btn btn-success">{{ $t('frontend.page-start.component_button1') }}</a>
                            <a href="/register" class="btn btn-info">{{ $t('frontend.page-start.component_button2') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            console.log('Frontend Component mounted.')
        }
    }
</script>
