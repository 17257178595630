import Vuex from 'vuex';
import vuexI18n from 'vuex-i18n';
import Locales from '../../js/vue-i18n-locales.generated';

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('../../general/js/bootstrap');
require('../../general/js/custom-general');
require('./vendor-frontend');
require('../../../../Modules/Frontend/Assets/js/module_frontend.js');


window.Vue = require('vue');

Vue.component(
    'passport-clients',
    require('../../js/components/passport/Clients.vue')
);

Vue.component(
    'passport-authorized-clients',
    require('../../js/components/passport/AuthorizedClients.vue')
);

Vue.component(
    'passport-personal-access-tokens',
    require('../../js/components/passport/PersonalAccessTokens.vue')
);


Vue.component('example', require('../../../../Modules/Frontend/Resources/views/vue/components/Example.vue'));

const store = new Vuex.Store();
Vue.use(vuexI18n.plugin, store);


Vue.i18n.add('en', Locales.en);
Vue.i18n.add('de', Locales.de);


const lang = document.documentElement.lang.substr(0, 2);

// set the start locale to use
Vue.i18n.set(lang);
Vue.config.ignoredElements = ['unsubscribe', 'webversion'];


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const app = new Vue({
    store,
    el: '#app',
});
