var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "card", staticStyle: { width: "100%" } }, [
          _c("div", { staticClass: "card-header" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("frontend.page-start.component_headline")) +
                "\n                "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.$t("frontend.page-start.component_text")) +
                "\n\n                    "
            ),
            _c(
              "div",
              {
                staticClass: "form-group",
                staticStyle: { "margin-top": "32px" }
              },
              [
                _c(
                  "a",
                  { staticClass: "btn btn-success", attrs: { href: "/login" } },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("frontend.page-start.component_button1"))
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "btn btn-info", attrs: { href: "/register" } },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("frontend.page-start.component_button2"))
                    )
                  ]
                )
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }