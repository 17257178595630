window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;

    window.$ = window.jQuery = require('jquery');
    window.shave = require('shave/dist/jquery.shave');
    require('jquery-ui-dist/jquery-ui.min');
    require('bootstrap');
    window.faker = require('faker');
    window.AOS = require('aos');
    window.MobileDetect = require('mobile-detect');
    window.moment = require('moment');
    window.toastr = require('toastr');
    window.numeral = require('numeral');
    window.Lazy = require('jquery-lazy');
    require('sticky-kit/dist/sticky-kit');

    //require('../../js/ziggy');
    require('select2');
    require('tablesorter');
    require('jquery-confirm');
    require('jquery-serializejson');
    require('jquery-match-height');
    require('chart.js');
    require('bootstrap-fileinput');
    require('bootstrap-fileinput/js/locales/de');
    require("bootstrap-fileinput/themes/fa/theme");
    require('owl.carousel');
    //require('gridstack/dist/gridstack.all');
    //require('ContentTools/build/content-tools');
    //require('trumbowyg');
    //require('trumbowyg/dist/langs/de.min');
    //require('jscolor-picker');
    require('jquery.simple-scroll-follow');
    require('perfect-scrollbar');
    //require('@ckeditor/ckeditor5-build-classic');

    //require('pagepiling.js');
    //require('smoothstate');

    require('../../../../Modules/Backend/Assets/js/vendor/coreui/coreui');
    require('../../../../Modules/Backend/Assets/js/vendor/coreui/coreui-utilities');
    //window.pdfjsLib = require('pdfjs-dist');

    /*
    window.pdfMake = require('pdfmake/build/pdfmake.min');
    var vfs = require('pdfmake/build/vfs_fonts');
    window.pdfMake.vfs = vfs.pdfMake.vfs;
    */
    
    require('datatables.net');
    require('datatables.net-responsive/js/dataTables.responsive');
    require('datatables.net-responsive-bs4/js/responsive.bootstrap4');
    require('datatables.net-keytable');
    require('datatables.net-select');
    require('datatables.net-colreorder');
    require('dt-colresize');
    require('datatables.net-buttons/js/dataTables.buttons');
    require('datatables.net-buttons-bs4/js/buttons.bootstrap4');
    require('datatables.net-buttons/js/buttons.print.js');
    require('datatables.net-buttons/js/buttons.colVis.js');
    require('datatables.net-buttons/js/buttons.html5.js');
    require('datatables.net-buttons/js/buttons.flash.js');
    //require('../../vendor/datatables/buttons.server-side.js');

    window.datetimepicker = require('tempusdominus-bootstrap-4');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
