export default {
    "de": {
        "passwords": {
            "password": "Passwörter müssen mindestens 6 Zeichen lang sein und korrekt bestätigt werden.",
            "reset": "Das Passwort wurde zurückgesetzt!",
            "sent": "Passworterinnerung wurde gesendet!",
            "token": "Der Passwort-Wiederherstellungs-Schlüssel ist ungültig oder abgelaufen.",
            "user": "Es konnte leider kein Nutzer mit dieser E-Mail-Adresse gefunden werden.",
            "pages": {
                "reset": {
                    "title": "Passwort zurücksetzen",
                    "email": "E-Mail",
                    "email_placeholder": "max.mustermann@domain.de",
                    "submit": "Passwortlink senden"
                },
                "reset_confirmation": {
                    "title": "Passwort zurücksetzen bestätigen",
                    "password": "Passwort",
                    "password_confirmation": "Passwort bestätigen",
                    "submit": "Passwort zurücksetzen"
                }
            },
            "email": {
                "subject": "Passwort zurücksetzen",
                "hello": "Hallo!",
                "line1": "Du erhältst diese E-Mail da du eine Passwort-Zurücksetzung angefordert hast.",
                "line2": "Falls du diese Anforderung nicht getätigt hast, kannst du diese E-Mail ignorieren.",
                "button": "Passwort zurücksetzen"
            }
        },
        "auth": {
            "failed": "Diese Kombination aus Zugangsdaten wurden nicht in unserer Datenbank gefunden.",
            "throttle": "Zu viele Loginversuche. Versuchen Sie es bitte in {seconds} Sekunden nochmal.",
            "password_strength": {
                "title": "Passwort-Stärke",
                "strength_label": "Stärke",
                "strengths": {
                    "worst": "Mieserabel",
                    "bad": "Schlecht",
                    "weak": "Schwach",
                    "good": "Gut",
                    "strong": "Sehr stark"
                }
            },
            "form": {
                "login": {
                    "title": "Anmelden",
                    "subtitle": "Melden Sie sich mit Ihrem Konto an",
                    "username_email": "Benutzername oder E-Mail",
                    "remember_me": "Anmeldung merken?",
                    "login": "Anmelden",
                    "forgot_password": "Passwort vergessen?",
                    "register": "Registrieren",
                    "register_now": "Jetzt registrieren",
                    "unsuccessful_login": "Diese Zugangsdaten sind leider nicht korrekt!",
                    "activation": {
                        "title": "Bitte aktivieren Sie Ihren Account!",
                        "submit": "E-Mail erneut senden?"
                    }
                },
                "register": {
                    "title": "Registrieren",
                    "dummy_button": "Test-Daten (nur lokal)",
                    "firstname": {
                        "label": "Vorname",
                        "placeholder": "Max"
                    },
                    "lastname": {
                        "label": "Nachname",
                        "placeholder": "Mustermann"
                    },
                    "username": {
                        "label": "Benutzername",
                        "placeholder": "Max1979"
                    },
                    "email": {
                        "label": "E-Mail",
                        "placeholder": "E-Mail"
                    },
                    "password": {
                        "label": "Passwort",
                        "placeholder": ""
                    },
                    "password_confirmation": {
                        "label": "Passwort bestätigen",
                        "placeholder": ""
                    },
                    "hint": "Tipps",
                    "required_info": "Pflicht, bitte fülle diese Felder aus.",
                    "submit": "Registrieren",
                    "successful_registration": "Vielen Dank für deine Registrierung! Bald erhältst du eine Aktivierungs-E-Mail von uns."
                }
            }
        },
        "frontend": {
            "page-activation": {
                "account_already_activated": "Ihr Account ist bereits aktiviert!",
                "account_successfully_activated": "Ihr Account wurde aktiviert!",
                "activation_failed": "Leider ist die Aktivierung fehlgeschlagen!",
                "activation_emails_lock": "Sie haben bereits drei E-Mails erhalten! Bitte verwenden Sie diese oder versuchen Sie es in 24 Stunden erneut.",
                "activation_email_sent": "Die E-Mail wurde erfolgreich an Sie gesendet!",
                "activation_email_resend_text": "Bevor Sie fortfahren können, prüfen Sie bitte Ihr Postfach und aktivieren Sie Ihr Konto.",
                "activation_email_resend_text2": "Sollten Sie keine E-Mail erhalten haben",
                "activation_email_resend_link": "klicken Sie hier um eine neue anzufordern",
                "email": {
                    "subject": "Account-Aktivierung - Bitte verifizieren Sie Ihren Account!",
                    "greeting": "Hallo!",
                    "line1": "Sie müssen Ihren Account erst aktivieren bevor Sie unseren Service nutzen können!",
                    "action-text": "E-Mail bestätigen",
                    "line2": "Vielen Dank das Sie sich für unseren Dienst entschieden haben!",
                    "line3": "Wenn Sie keinen Account angelegt haben, können Sie diese E-Mail ignorieren."
                }
            },
            "page-lock": {
                "index": {
                    "headline": "Datensatz ist gesperrt!",
                    "title": "Verdammt...",
                    "subtitle": "Dieser Datensatz wird wohl leider verwendet:",
                    "video_error_message": "Ihr Browser unterstützt den Video-Tag nicht!"
                }
            },
            "page-start": {
                "title": "Willkommen!",
                "headline": "Hallo Welt!",
                "subheadline": "Diese Ansicht wird von folgendem Modul geladen:",
                "component_headline": "Frontend Komponente",
                "component_text": "Ich bin eine Beispielkomponente!",
                "component_button1": "Anmelden",
                "component_button2": "Registrieren"
            },
            "core": {
                "modal": {
                    "title": "Bestätigung",
                    "confirmation-message": "Sind Sie sicher, dass Sie diesen Eintrag löschen wollen?"
                },
                "table": {
                    "created_at": "Erstellt",
                    "actions": "Aktionen",
                    "status": "Status",
                    "thumbnail": "Thumbnail"
                },
                "tab": {
                    "english": "Englisch",
                    "french": "Französisch",
                    "german": "Deutsch",
                    "dutch": "Holländisch",
                    "italian": "Italienisch",
                    "greek": "Griechisch",
                    "spanish": "Spanisch",
                    "portuguese": "Portugiesisch",
                    "arabic": "Arabisch",
                    "czech": "Tschechisch",
                    "korean": "Korean"
                },
                "button": {
                    "cancel": "Abbrechen",
                    "create": "Erstellen",
                    "update": "Speichern",
                    "delete": "Löschen",
                    "reset": "Zurücksetzen",
                    "update_and_back": "Speichern und zurück"
                },
                "save": "Speichern",
                "confirm": "Bestätigen",
                "move": "Verschieben",
                "breadcrumb": {
                    "home": "Startseite"
                },
                "title": {
                    "translatable_fields": "Übersetzbare Felder",
                    "non_translatable_fields": "Nicht übersetzbare Felder",
                    "create_resource": "Erstelle {name}",
                    "edit_resource": "Bearbeite {name}"
                },
                "general": {
                    "available_keyboard_shortcuts": "Auf dieser Seite sind Tastaturkürzel verfügbar",
                    "view_website": "Webseite anschauen",
                    "complete_your_profile": "Vervollständigen Sie Ihr Profil",
                    "sign_out": "Abmelden"
                },
                "messages": {
                    "resource_created": "{name} erflolgreich erstellt.",
                    "resource_not_found": "{name} nicht gefunden.",
                    "resource_updated": "{name} erflolgreich auktualisiert.",
                    "resource_deleted": "{name} erflolgreich gelöscht."
                },
                "back": "Zurück",
                "back_to_index": "Zurück zur Übersicht",
                "permission_denied": "Zugriff verweigert. (Erforderliche Berechtigung: \"{permission}\")"
            },
            "navigation": {
                "top": {
                    "backend": "Backend",
                    "userbackend": "Benutzer-Backend",
                    "logout": "Abmelden",
                    "hello": "Hallo"
                },
                "primary": {
                    "start": "Start",
                    "space": "Mein Space",
                    "login": "Anmelden",
                    "logout": "Abmelden",
                    "register": "Registrieren"
                }
            }
        },
        "pagination": {
            "previous": "&laquo; Zurück",
            "next": "Weiter &raquo;"
        },
        "backend": {
            "page-exception": {
                "general": {
                    "back-button": "Zu allen Fehlermeldungen",
                    "truncate-exceptions": "Fehler-Tabelle leeren"
                },
                "index": {
                    "headline": "Fehler listen",
                    "title": "Fehler listen",
                    "subtitle": ""
                },
                "show": {
                    "headline": "Fehler anzeigen",
                    "title": "Fehler anzeigen",
                    "subtitle": "",
                    "content": {
                        "class": {
                            "label": "Klasse"
                        },
                        "file": {
                            "label": "Datei"
                        },
                        "code": {
                            "label": "Code"
                        },
                        "status_code": {
                            "label": "Status Code"
                        },
                        "line": {
                            "label": "Zeile"
                        },
                        "method": {
                            "label": "Methode"
                        },
                        "user": {
                            "label": "Benutzer"
                        },
                        "message": {
                            "label": "Meldung"
                        },
                        "trace": {
                            "label": "Spur"
                        },
                        "input_data": {
                            "label": "Input"
                        }
                    }
                },
                "datatable": {
                    "columns": {
                        "class": "Klasse",
                        "message": "Meldung"
                    }
                }
            },
            "page-job": {
                "general": {
                    "back-button": "Zu allen Jobs",
                    "create-new-job": "Job erstellen",
                    "truncate-jobs": "Job-Tabelle leeren"
                },
                "index": {
                    "headline": "Jobs listen",
                    "title": "Jobs listen",
                    "subtitle": ""
                },
                "create": {
                    "headline": "Job erstellen",
                    "title": "Job erstellen",
                    "subtitle": "",
                    "content": {
                        "type": {
                            "label": "Type"
                        },
                        "users": {
                            "label": "Users"
                        },
                        "title": {
                            "label": "Title",
                            "placeholder": "New update available"
                        },
                        "message": {
                            "label": "Message",
                            "placeholder": "Hey girls and boys, here is an update..."
                        }
                    }
                },
                "show": {
                    "headline": "Job anzeigen",
                    "title": "Job anzeigen",
                    "subtitle": ""
                },
                "datatable": {
                    "columns": {
                        "payload": "Name",
                        "queue": "Schlange",
                        "attempts": "Versuche",
                        "reserved_at": "Reserviert am",
                        "available_at": "Verfügbar am"
                    }
                }
            },
            "space-page-media": {
                "general": {
                    "back-button": "Zu allen Medien",
                    "create-user": "Datei erstellen",
                    "sizes": {
                        "original": "Original",
                        "large": "Groß",
                        "medium": "Mittel",
                        "small": "Klein",
                        "thumb": "Thumb"
                    },
                    "form": {
                        "action": "Aktion",
                        "image_size": "Bildgröße",
                        "path": "Pfad",
                        "select_image": "Bild wählen"
                    }
                },
                "index": {
                    "headline": "Medien listen",
                    "title": "Medien listen",
                    "subtitle": "",
                    "no-images-found": "Keine Dateien gefunden...",
                    "path-deletion-disabled": "Dieser Pfad kann aufgrund von vorhandenen Bildern und/oder Unterpfaden nicht gelöscht werden!",
                    "path-deletion-failed": "Dieser Pfad konnte nicht gelöscht werden!",
                    "notifications": {
                        "media": {
                            "deletion-successful": "Datei wurde erfolgreich gelöscht!",
                            "deletion-failed": "Löschen von Datei fehlgeschlagen!"
                        },
                        "path": {
                            "creation-successful": "Pfad wurde erfolgreich erstellt!",
                            "creation-failed": "Pfad konnte nicht erstellt werden!",
                            "deletion-successful": "Pfad wurde erfolgreich gelöscht!",
                            "deletion-failed": "Löschen vom Pfad fehlgeschlagen!"
                        }
                    }
                },
                "create": {
                    "headline": "Medien erstellen",
                    "title": "Medien erstellen",
                    "subtitle": "",
                    "content": {
                        "test": {
                            "label": "test",
                            "placeholder": ""
                        }
                    }
                },
                "edit": {
                    "headline": "Datei bearbeiten",
                    "title": "Datei bearbeiten",
                    "subtitle": ""
                },
                "modals": {
                    "upload-media": {
                        "title": "Dateien hochladen",
                        "drop-area-title": "Dateien zum Hochladen hier hinziehen...",
                        "errors": {
                            "maximum_file_size_exceeded": "Diese Datei ist zu groß! Die maximale Größe beträgt",
                            "wrong_file_type": "Dieser Dateityp ist nicht erlaubt!",
                            "file_missing": "Es wurde keine Datei hochgeladen!"
                        },
                        "maximum-filesize-title": "Die maximale Dateigröße beträgt",
                        "allowed-filetypes-title": "Erlaubt sind folgende Dateitypen"
                    },
                    "move-media": {
                        "title": "Dateien bewegen",
                        "content": {
                            "move_to_path": "Nach Pfad bewegen...",
                            "error_title": "Dateien konnten nicht verschoben werden!",
                            "error_message": "Bitte kontaktieren Sie den Administrator...",
                            "success_title": "Dateien wurden erfolgreich verschoben"
                        }
                    },
                    "browse-media": {
                        "title": "Dateien wählen",
                        "content": []
                    },
                    "edit-media": {
                        "title": "Datei bearbeiten",
                        "content": {
                            "name": "Name"
                        }
                    },
                    "create-path": {
                        "title": "Pfad erstellen",
                        "content": {
                            "create_in_path": {
                                "label": "In Pfad erstellen...",
                                "create_in_root": "Im Hauptverzeichnis erstellen"
                            },
                            "path": {
                                "label": "Pfad"
                            }
                        },
                        "errors": {
                            "path-exists-title": "Der Ordner existiert bereits!",
                            "path-exists-message": "Bitte wählen Sie einen anderen Namen...",
                            "path-field-error": "Der Pfad existiert bereits!"
                        }
                    }
                }
            },
            "page-lock": {
                "index": {
                    "headline": "Datensatz ist gesperrt!",
                    "title": "Verdammt...",
                    "subtitle": "Dieser Datensatz wird wohl leider verwendet:",
                    "video_error_message": "Ihr Browser unterstützt den Video-Tag nicht!"
                }
            },
            "header": {
                "left-nav": {
                    "to-frontend": "Zum Frontend",
                    "to-backend": "Zum Admin-Backend"
                },
                "right-nav": {
                    "no-notifications": "Keine neuen Nachrichten!"
                },
                "quickinfo_bar": {
                    "revision": {
                        "title": "Revisionen"
                    },
                    "exception": {
                        "title": "Fehler"
                    }
                }
            },
            "page-dashboard": {
                "widgets": {
                    "sample-widget": {
                        "title": "Analyse",
                        "top-buttons": {
                            "day": "Tag",
                            "month": "Monat",
                            "year": "Jahr"
                        },
                        "footer": {
                            "visits": "Besucher",
                            "unique": "Eindeutig",
                            "pageviews": "Aufrufe",
                            "new_users": "Neue Nutzer",
                            "bounce_rate": "Bounce Rate"
                        }
                    }
                }
            },
            "page-setting": {
                "general": [],
                "index": {
                    "headline": "Einstellungen",
                    "title": "Einstellungen",
                    "subtitle": "",
                    "tabs": {
                        "tab-login-register": {
                            "title": "Login & Registrierung",
                            "content": {
                                "enable_login": {
                                    "label": "Login aktivieren"
                                },
                                "enable_sub_users": {
                                    "label": "Unterbenutzer erlauben"
                                },
                                "enable_registration": {
                                    "label": "Registrierung aktivieren"
                                },
                                "default_registration_role": {
                                    "label": "Standardrolle bei der Registrierung"
                                },
                                "enable_password_strength_validation": {
                                    "label": "Passwort-Stärke prüfen"
                                },
                                "password_strength_validation_rules": {
                                    "label": "Passwort-Stärke Regeln"
                                }
                            }
                        },
                        "tab-maintenance": {
                            "title": "Wartung",
                            "content": {
                                "enable_maintenance": {
                                    "label": "Wartungsmodus aktivieren"
                                },
                                "caches": {
                                    "clear-cache": {
                                        "label": "Cache leeren"
                                    },
                                    "clear-view": {
                                        "label": "View-Cache leeren"
                                    },
                                    "clear-config": {
                                        "label": "Config-Cache leeren"
                                    },
                                    "cache-config": {
                                        "label": "Config-Cache erstellen"
                                    },
                                    "clear-route": {
                                        "label": "Route-Cache leeren"
                                    },
                                    "cache-route": {
                                        "label": "Route-Cache erstellen"
                                    }
                                }
                            }
                        },
                        "tab-api": {
                            "title": "API",
                            "content": {
                                "enable_api": {
                                    "label": "API aktivieren"
                                }
                            }
                        },
                        "tab-media": {
                            "title": "Medien",
                            "content": {
                                "maximum_upload_size_in_mb": {
                                    "label": "Maximale Upload-Größe (mb)"
                                }
                            }
                        }
                    }
                }
            },
            "page-permission": {
                "general": {
                    "back-button": "Zu allen Berechtigungen",
                    "create-role": "Berechtigung erstellen"
                },
                "index": {
                    "headline": "Berechtigungen listen",
                    "title": "Berechtigungen listen",
                    "subtitle": "",
                    "tabs": {
                        "tab-role": {
                            "title": "Berechtigung",
                            "content": {
                                "test": {
                                    "label": "test"
                                }
                            }
                        }
                    }
                },
                "create": {
                    "headline": "Berechtigung erstellen",
                    "title": "Berechtigung erstellen",
                    "subtitle": "",
                    "content": {
                        "name": {
                            "label": "Interner Name",
                            "placeholder": "redakteur"
                        },
                        "display_name": {
                            "label": "Angezeigter Name",
                            "placeholder": "Redakteur"
                        },
                        "description": {
                            "label": "Beschreibung",
                            "placeholder": "Dieser Benutzer kann folgendes..."
                        },
                        "group_identifier": {
                            "label": "Gruppe",
                            "placeholder": "benutzer"
                        }
                    }
                },
                "edit": {
                    "headline": "Berechtigung bearbeiten",
                    "title": "Berechtigung bearbeiten",
                    "subtitle": ""
                },
                "datatable": {
                    "columns": {
                        "name": "Slug",
                        "display_name": "Titel"
                    }
                }
            },
            "page-user": {
                "general": {
                    "back-button": "Zu allen Benutzern",
                    "create-user": "Benutzer erstellen"
                },
                "index": {
                    "headline": "Benutzer listen",
                    "title": "Benutzer listen",
                    "subtitle": "",
                    "tabs": {
                        "tab-user": {
                            "title": "Benutzer"
                        }
                    }
                },
                "create": {
                    "headline": "Benutzer erstellen",
                    "title": "Benutzer erstellen",
                    "subtitle": "",
                    "content": {
                        "skip_activation": {
                            "label": "Aktivierung überspringen",
                            "placeholder": ""
                        },
                        "parent_user_id": {
                            "label": "Übergeordneter Benutzer",
                            "placeholder": ""
                        },
                        "account_type": {
                            "label": "Konto-Typ",
                            "placeholder": ""
                        },
                        "username": {
                            "label": "Benutzername",
                            "placeholder": "max1992"
                        },
                        "firstname": {
                            "label": "Vorname",
                            "placeholder": "Max"
                        },
                        "lastname": {
                            "label": "Nachname",
                            "placeholder": "Mustermann"
                        },
                        "email": {
                            "label": "E-Mail",
                            "placeholder": "max.mustermann@domain.de"
                        },
                        "roles": {
                            "label": "Rollen"
                        },
                        "password": {
                            "label": "Passwort"
                        },
                        "repeat_password": {
                            "label": "Passwort wiederholen"
                        }
                    }
                },
                "edit": {
                    "headline": "Benutzer bearbeiten",
                    "title": "Benutzer bearbeiten",
                    "subtitle": "",
                    "api_token_button": "Token erneuern",
                    "api_token_flash": "API-Token erfolgreich aktualisiert!"
                },
                "delete": {
                    "error": "Du kannst deinen eigenen Benutzer nicht löschen!"
                },
                "datatable": {
                    "columns": {
                        "firstname": "Vorname",
                        "lastname": "Nachname",
                        "email": "E-Mail",
                        "roles": "Rollen"
                    }
                }
            },
            "page-role": {
                "general": {
                    "back-button": "Zu allen Rollen",
                    "create-role": "Rolle erstellen"
                },
                "index": {
                    "headline": "Rollen listen",
                    "title": "Rollen listen",
                    "subtitle": "",
                    "tabs": {
                        "tab-role": {
                            "title": "Rolle",
                            "content": {
                                "test": {
                                    "label": "test"
                                }
                            }
                        },
                        "tab-permission": {
                            "title": "Berechtigungen",
                            "content": {
                                "test": {
                                    "label": "test"
                                }
                            }
                        }
                    }
                },
                "create": {
                    "headline": "Rolle erstellen",
                    "title": "Rolle erstellen",
                    "subtitle": "",
                    "content": {
                        "name": {
                            "label": "Interner Name",
                            "placeholder": "redakteur"
                        },
                        "display_name": {
                            "label": "Angezeigter Name",
                            "placeholder": "Redakteur"
                        },
                        "description": {
                            "label": "Beschreibung",
                            "placeholder": "Dieser Benutzer kann folgendes..."
                        }
                    }
                },
                "edit": {
                    "headline": "Rolle bearbeiten",
                    "title": "Rolle bearbeiten",
                    "subtitle": ""
                },
                "datatable": {
                    "columns": {
                        "name": "Slug",
                        "display_name": "Titel"
                    }
                }
            },
            "page-revision": {
                "general": {
                    "back-button": "Zu allen Revisionen",
                    "truncate-revisions": "Revisions-Tabelle leeren"
                },
                "index": {
                    "headline": "Revisionen listen",
                    "title": "Revisionen listen",
                    "subtitle": ""
                },
                "show": {
                    "headline": "Revision anzeigen",
                    "title": "Revision anzeigen",
                    "subtitle": "",
                    "content": {
                        "revisionable_id": {
                            "label": "Rev-ID"
                        },
                        "revisionable_type": {
                            "label": "Rev-Typ"
                        },
                        "user": {
                            "label": "Benutzer-ID"
                        },
                        "key": {
                            "label": "Schlüssel"
                        },
                        "old_value": {
                            "label": "Alter Wert"
                        },
                        "new_value": {
                            "label": "Neuer Wert"
                        }
                    }
                },
                "datatable": {
                    "columns": {
                        "revisionable_id": "Rev-ID",
                        "revisionable_type": "Rev-Typ",
                        "user": "Benutzer-ID",
                        "key": "Schlüssel",
                        "old_value": "Alter Wert",
                        "new_value": "Neuer Wert"
                    }
                }
            },
            "core": {
                "modal": {
                    "title": "Bestätigung",
                    "confirmation-message": "Sind Sie sicher, dass Sie diesen Eintrag löschen wollen?"
                },
                "table": {
                    "created_at": "Erstellt",
                    "actions": "Aktionen",
                    "status": "Status",
                    "thumbnail": "Thumbnail"
                },
                "tab": {
                    "english": "Englisch",
                    "french": "Französisch",
                    "german": "Deutsch",
                    "dutch": "Holländisch",
                    "italian": "Italienisch",
                    "greek": "Griechisch",
                    "spanish": "Spanisch",
                    "portuguese": "Portugiesisch",
                    "arabic": "Arabisch",
                    "czech": "Tschechisch",
                    "korean": "Korean"
                },
                "button": {
                    "cancel": "Abbrechen",
                    "create": "Erstellen",
                    "update": "Speichern",
                    "delete": "Löschen",
                    "reset": "Zurücksetzen",
                    "update_and_back": "Speichern und zurück"
                },
                "save": "Speichern",
                "confirm": "Bestätigen",
                "move": "Verschieben",
                "breadcrumb": {
                    "home": "Startseite"
                },
                "title": {
                    "translatable_fields": "Übersetzbare Felder",
                    "non_translatable_fields": "Nicht übersetzbare Felder",
                    "create_resource": "Erstelle {name}",
                    "edit_resource": "Bearbeite {name}"
                },
                "general": {
                    "available_keyboard_shortcuts": "Auf dieser Seite sind Tastaturkürzel verfügbar",
                    "view_website": "Webseite anschauen",
                    "complete_your_profile": "Vervollständigen Sie Ihr Profil",
                    "sign_out": "Abmelden"
                },
                "messages": {
                    "resource_created": "{name} erflolgreich erstellt.",
                    "resource_not_found": "{name} nicht gefunden.",
                    "resource_updated": "{name} erflolgreich auktualisiert.",
                    "resource_deleted": "{name} erflolgreich gelöscht."
                },
                "back": "Zurück",
                "back_to_index": "Zurück zur Übersicht",
                "permission_denied": "Zugriff verweigert. (Erforderliche Berechtigung: \"{permission}\")"
            },
            "sidebar": {
                "headers": {
                    "personal": "Persönliches",
                    "administration": "Administration"
                },
                "dashboard": {
                    "label": "Dashboard"
                },
                "users": {
                    "label": "Benutzer",
                    "submenu": {
                        "list": "Benutzer listen",
                        "create": "Benutzer erstellen"
                    }
                },
                "roles": {
                    "label": "Rollen",
                    "submenu": {
                        "list": "Rollen listen",
                        "create": "Rolle erstellen"
                    }
                },
                "media": {
                    "label": "Medien",
                    "submenu": {
                        "list": "Medien listen",
                        "create": "Medien hochladen"
                    }
                },
                "permissions": {
                    "label": "Berechtigungen",
                    "submenu": {
                        "list": "Berechtigungen listen",
                        "create": "Berechtigungen erstellen"
                    }
                },
                "jobs": {
                    "label": "Jobs",
                    "submenu": {
                        "list": "Jobs listen",
                        "create": "Job erstellen"
                    }
                },
                "exceptions": {
                    "label": "Fehler",
                    "submenu": {
                        "list": "Fehler listen"
                    }
                },
                "revisions": {
                    "label": "Revisionen",
                    "submenu": {
                        "list": "Revisionen listen"
                    }
                },
                "notifications": {
                    "label": "Nachrichten",
                    "submenu": {
                        "list": "Nachrichten listen",
                        "create": "Nachricht erstellen"
                    }
                },
                "profile": {
                    "label": "Mein Profil",
                    "submenu": {
                        "edit": "Profil bearbeiten"
                    }
                },
                "setting": {
                    "label": "Einstellungen"
                }
            },
            "modal": {
                "notification": {
                    "title": "Du hast neue Nachrichten!",
                    "body": {
                        "line1": "Bitte nimm dir die Zeit für die Nachrichten, diese könnten wichtig sein.",
                        "line2": "Denk dran diese danach als GELESEN zu markieren!"
                    }
                },
                "confirm": {
                    "title": "Sind Sie sicher?",
                    "body": {
                        "text": "Diese Aktion kann womöglich nicht mehr rückgängig gemacht werden!"
                    },
                    "buttons": {
                        "confirm": "Fortfahren",
                        "cancel": "Abbrechen"
                    }
                }
            }
        },
        "datatable": {
            "buttons": {
                "copy": "Kopieren",
                "print": "Drucken"
            }
        },
        "routes": {
            "product": {
                "index": "/produkte",
                "show": "/produkt/{id}/{slug}"
            },
            "manufacturer": {
                "index": "/alle-hersteller",
                "show": "/hersteller/{id}/{slug}"
            },
            "lesson": {
                "index": "/lehrmaterialien",
                "show": "/lehrmaterial/{id}/{slug}"
            }
        },
        "validation": {
            "accepted": "{attribute} muss akzeptiert werden.",
            "active_url": "{attribute} ist keine gültige Internet-Adresse.",
            "after": "{attribute} muss ein Datum nach dem {date} sein.",
            "after_or_equal": "{attribute} muss ein Datum nach dem {date} oder gleich dem {date} sein.",
            "alpha": "{attribute} darf nur aus Buchstaben bestehen.",
            "alpha_dash": "{attribute} darf nur aus Buchstaben, Zahlen, Binde- und Unterstrichen bestehen.",
            "alpha_num": "{attribute} darf nur aus Buchstaben und Zahlen bestehen.",
            "array": "{attribute} muss ein Array sein.",
            "before": "{attribute} muss ein Datum vor dem {date} sein.",
            "before_or_equal": "{attribute} muss ein Datum vor dem {date} oder gleich dem {date} sein.",
            "between": {
                "numeric": "{attribute} muss zwischen {min} & {max} liegen.",
                "file": "{attribute} muss zwischen {min} & {max} Kilobytes groß sein.",
                "string": "{attribute} muss zwischen {min} & {max} Zeichen lang sein.",
                "array": "{attribute} muss zwischen {min} & {max} Elemente haben."
            },
            "boolean": "{attribute} muss entweder 'true' oder 'false' sein.",
            "confirmed": "{attribute} stimmt nicht mit der Bestätigung überein.",
            "date": "{attribute} muss ein gültiges Datum sein.",
            "date_format": "{attribute} entspricht nicht dem gültigen Format für {format}.",
            "different": "{attribute} und {other} müssen sich unterscheiden.",
            "digits": "{attribute} muss {digits} Stellen haben.",
            "digits_between": "{attribute} muss zwischen {min} und {max} Stellen haben.",
            "dimensions": "{attribute} hat ungültige Bildabmessungen.",
            "distinct": "{attribute} beinhaltet einen bereits vorhandenen Wert.",
            "email": "{attribute} muss eine gültige E-Mail-Adresse sein.",
            "exists": "Der gewählte Wert für {attribute} ist ungültig.",
            "file": "{attribute} muss eine Datei sein.",
            "filled": "{attribute} muss ausgefüllt sein.",
            "gt": {
                "numeric": "{attribute} muss mindestens {min} sein.",
                "file": "{attribute} muss mindestens {min} Kilobytes groß sein.",
                "string": "{attribute} muss mindestens {min} Zeichen lang sein.",
                "array": "{attribute} muss mindestens {min} Elemente haben."
            },
            "gte": {
                "numeric": "{attribute} muss größer oder gleich {min} sein.",
                "file": "{attribute} muss größer oder gleich {min} Kilobytes sein.",
                "string": "{attribute} muss größer oder gleich {min} Zeichen lang sein.",
                "array": "{attribute} muss größer oder gleich {min} Elemente haben."
            },
            "image": "{attribute} muss ein Bild sein.",
            "in": "Der gewählte Wert für {attribute} ist ungültig.",
            "in_array": "Der gewählte Wert für {attribute} kommt nicht in {other} vor.",
            "integer": "{attribute} muss eine ganze Zahl sein.",
            "ip": "{attribute} muss eine gültige IP-Adresse sein.",
            "ipv4": "{attribute} muss eine gültige IPv4-Adresse sein.",
            "ipv6": "{attribute} muss eine gültige IPv6-Adresse sein.",
            "json": "{attribute} muss ein gültiger JSON-String sein.",
            "lt": {
                "numeric": "{attribute} muss kleiner {min} sein.",
                "file": "{attribute} muss kleiner {min} Kilobytes groß sein.",
                "string": "{attribute} muss kleiner {min} Zeichen lang sein.",
                "array": "{attribute} muss kleiner {min} Elemente haben."
            },
            "lte": {
                "numeric": "{attribute} muss kleiner oder gleich {min} sein.",
                "file": "{attribute} muss kleiner oder gleich {min} Kilobytes sein.",
                "string": "{attribute} muss kleiner oder gleich {min} Zeichen lang sein.",
                "array": "{attribute} muss kleiner oder gleich {min} Elemente haben."
            },
            "max": {
                "numeric": "{attribute} darf maximal {max} sein.",
                "file": "{attribute} darf maximal {max} Kilobytes groß sein.",
                "string": "{attribute} darf maximal {max} Zeichen haben.",
                "array": "{attribute} darf nicht mehr als {max} Elemente haben."
            },
            "mimes": "{attribute} muss den Dateityp {values} haben.",
            "mimetypes": "{attribute} muss den Dateityp {values} haben.",
            "min": {
                "numeric": "{attribute} muss mindestens {min} sein.",
                "file": "{attribute} muss mindestens {min} Kilobytes groß sein.",
                "string": "{attribute} muss mindestens {min} Zeichen lang sein.",
                "array": "{attribute} muss mindestens {min} Elemente haben."
            },
            "not_in": "Der gewählte Wert für {attribute} ist ungültig.",
            "not_regex": "{attribute} hat ein ungültiges Format.",
            "numeric": "{attribute} muss eine Zahl sein.",
            "present": "{attribute} muss vorhanden sein.",
            "regex": "{attribute} Format ist ungültig.",
            "required": "{attribute} muss ausgefüllt sein.",
            "required_if": "{attribute} muss ausgefüllt sein, wenn {other} {value} ist.",
            "required_unless": "{attribute} muss ausgefüllt sein, wenn {other} nicht {values} ist.",
            "required_with": "{attribute} muss angegeben werden, wenn {values} ausgefüllt wurde.",
            "required_with_all": "{attribute} muss angegeben werden, wenn {values} ausgefüllt wurde.",
            "required_without": "{attribute} muss angegeben werden, wenn {values} nicht ausgefüllt wurde.",
            "required_without_all": "{attribute} muss angegeben werden, wenn keines der Felder {values} ausgefüllt wurde.",
            "same": "{attribute} und {other} müssen übereinstimmen.",
            "size": {
                "numeric": "{attribute} muss gleich {size} sein.",
                "file": "{attribute} muss {size} Kilobyte groß sein.",
                "string": "{attribute} muss {size} Zeichen lang sein.",
                "array": "{attribute} muss genau {size} Elemente haben."
            },
            "string": "{attribute} muss ein String sein.",
            "timezone": "{attribute} muss eine gültige Zeitzone sein.",
            "unique": "{attribute} ist schon vergeben.",
            "uploaded": "{attribute} konnte nicht hochgeladen werden.",
            "url": "{attribute} muss eine URL sein.",
            "case_diff": "{attribute} muss Groß- und Kleinschreibung beinhalten.",
            "numbers": "{attribute} muss Zahlen enthalten.",
            "letters": "{attribute} muss Buchstaben enthalten.",
            "symbols": "{attribute} muss Sonderzeichen enthalten.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Name",
                "username": "Benutzername",
                "email": "E-Mail-Adresse",
                "first_name": "Vorname",
                "last_name": "Nachname",
                "password": "Passwort",
                "password_confirmation": "Passwort-Bestätigung",
                "city": "Stadt",
                "country": "Land",
                "address": "Adresse",
                "phone": "Telefonnummer",
                "mobile": "Handynummer",
                "age": "Alter",
                "sex": "Geschlecht",
                "gender": "Geschlecht",
                "day": "Tag",
                "month": "Monat",
                "year": "Jahr",
                "hour": "Stunde",
                "minute": "Minute",
                "second": "Sekunde",
                "title": "Titel",
                "content": "Inhalt",
                "description": "Beschreibung",
                "excerpt": "Auszug",
                "date": "Datum",
                "time": "Uhrzeit",
                "available": "verfügbar",
                "size": "Größe"
            }
        },
        "general": {
            "all": "Alle",
            "general": "Allgemein",
            "primary": "Primär",
            "secondary": "Sekundär",
            "sub": "Untergeordnet",
            "new": "Neu",
            "default": "Standard",
            "info": "Info",
            "warning": "Warnung",
            "success": "Erfolg",
            "actions": "Aktionen",
            "read_more": "Mehr erfahren",
            "account": "Konto",
            "profile": "Profil",
            "settings": "Einstellungen",
            "logout": "Abmelden",
            "notifications": "Nachrichten",
            "save": "Speichern",
            "create": "Erstellen",
            "update": "Aktualisieren",
            "delete": "Löschen",
            "created_at": "Erstellt am",
            "language": "Sprache",
            "updated_at": "Aktualisiert am",
            "delete-row-button": "Gewählte Zeilen löschen?",
            "notification_email": {
                "whoops": "Hoppla!",
                "hello": "Hallo!",
                "regards": "Grüße",
                "subcopy_line1": "Falls Sie Probleme beim Klicken von dem",
                "subcopy_line2": "Knopf haben, kopieren und fügen Sie diese URL in Ihre Browserleiste ein: [{actionURL}]({actionURL})"
            },
            "required_field": "Pflichtfeld",
            "cleared_successfully": "Erfolgreich geleert!",
            "deleted_successfully": "Erfolgreich gelöscht!",
            "truncated_successfully": "Erfolgreich geleert!",
            "success_created_title": "Erfolgreich erstellt!",
            "success_created_subtitle": "Sie werden in wenigen Sekunden weitergeleitet...",
            "success_update_title": "Erfolgreich aktualisiert!",
            "technical_error_title": "Es gab einen technischen Fehler!",
            "technical_error_text": "Bitte kontaktiere den Administrator oder versuche es später erneut...",
            "error_title": "Bitte Felder prüfen!",
            "error_text": "Senden Sie das Formular anschließend erneut...",
            "cronjob_successful": "Cronjob erfolgreich ausgeführt!",
            "cronjob_failed": "Cronjob fehlgeschlagen!",
            "characters_remaining": "Zeichen übrig",
            "error_pages": {
                "503": {
                    "title": "503 - Seite nicht verfügbar!",
                    "text": "Wir machen gerade eine kleine Kaffeepause..."
                },
                "403": {
                    "title": "403 - Zugriff nicht gestattet!",
                    "text": "Das war wohl nix..."
                },
                "404": {
                    "title": "404 - Seite nicht gefunden",
                    "text": "Hoppla, Sie haben sich wohl verirrt..."
                }
            },
            "google": {
                "analytics_deactivated": "Das Tracking durch Google Analytics wurde in Ihrem Browser für diese Website deaktiviert."
            }
        }
    },
    "en": {
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address.",
            "pages": {
                "reset": {
                    "title": "Reset Password",
                    "email": "E-Mail",
                    "email_placeholder": "john.doe@domain.com",
                    "submit": "Send Passwordlink"
                },
                "reset_confirmation": {
                    "title": "Confirm password reset",
                    "password": "Password",
                    "password_confirmation": "Confirm password",
                    "submit": "Reset password"
                }
            },
            "email": {
                "subject": "Reset Password Notification",
                "hello": "Hello!",
                "line1": "You are receiving this email because we received a password reset request for your account.",
                "line2": "If you did not request a password reset, no further action is required.",
                "button": "Reset Password"
            }
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "password_strength": {
                "title": "Password Strength",
                "strength_label": "Strength",
                "strengths": {
                    "worst": "Worst",
                    "bad": "Bad",
                    "weak": "Weak",
                    "good": "Good",
                    "strong": "Strong"
                }
            },
            "form": {
                "login": {
                    "title": "Login",
                    "subtitle": "Sign In to your account",
                    "username_email": "Username or E-Mail",
                    "remember_me": "Remember me?",
                    "login": "Login",
                    "forgot_password": "Forgot password?",
                    "register": "Register",
                    "register_now": "Register now",
                    "unsuccessful_login": "These credentials do not match our records.",
                    "activation": {
                        "title": "Please activate your account!",
                        "submit": "Resend activation e-mail?"
                    }
                },
                "register": {
                    "title": "Register",
                    "dummy_button": "Dummy-Data (only local)",
                    "firstname": {
                        "label": "Firstname",
                        "placeholder": "John"
                    },
                    "lastname": {
                        "label": "Lastname",
                        "placeholder": "Doe"
                    },
                    "username": {
                        "label": "Username",
                        "placeholder": "John1979"
                    },
                    "email": {
                        "label": "E-Mail",
                        "placeholder": "E-Mail"
                    },
                    "password": {
                        "label": "Password",
                        "placeholder": ""
                    },
                    "password_confirmation": {
                        "label": "Confirm Password",
                        "placeholder": ""
                    },
                    "hint": "Hint",
                    "required_info": "Required, please fill out those fields.",
                    "submit": "Register",
                    "successful_registration": "Thank you for your registration! You will soon receive an activation e-mail."
                }
            }
        },
        "frontend": {
            "page-activation": {
                "account_already_activated": "Your account is already activated!!",
                "account_successfully_activated": "You have activated your account!",
                "activation_failed": "Your activation could not be completed!",
                "activation_emails_lock": "You have already received three activation emails, please use these or try again in the ext 24 hours.",
                "activation_email_sent": "Activation E-Mail was sent!",
                "activation_email_resend_text": "Before proceeding, please check your email for a verification link.",
                "activation_email_resend_text2": "If you did not receive the email",
                "activation_email_resend_link": "click here to request another",
                "email": {
                    "subject": "Account Activation - please verify",
                    "greeting": "Hello!",
                    "line1": "You must verify your e-mail before you can use our service!",
                    "action-text": "Verify e-mail",
                    "line2": "Thank you for choosing our service.",
                    "line3": "If you did not create an account, no further action is required."
                }
            },
            "page-lock": {
                "index": {
                    "headline": "Data is locked!",
                    "title": "Damn it...",
                    "subtitle": "This data is currently being edited by:",
                    "video_error_message": "Your Browser doesn't support this tag!"
                }
            },
            "page-start": {
                "title": "Welcome!",
                "headline": "Hello World!",
                "subheadline": "This view is loaded from module:",
                "component_headline": "Frontend Component",
                "component_text": "I'm an example component!",
                "component_button1": "Login",
                "component_button2": "Register"
            },
            "core": {
                "modal": {
                    "title": "Confirmation",
                    "confirmation-message": "Are you sure you want to delete this record?"
                },
                "table": {
                    "created_at": "Created at",
                    "actions": "Actions",
                    "thumbnail": "Thumbnail"
                },
                "tab": {
                    "english": "English",
                    "french": "French",
                    "dutch": "Dutch",
                    "italian": "Italian",
                    "greek": "Ελληνικά",
                    "spanish": "Spanish",
                    "polish": "Polish",
                    "portuguese": "Portuguese",
                    "arabic": "Arabic",
                    "macedonian": "Македонски",
                    "turkish": "Türkçe",
                    "czech": "Czech",
                    "swedish": "Svenska",
                    "korean": "Korean"
                },
                "button": {
                    "cancel": "Cancel",
                    "create": "Create",
                    "update": "Update",
                    "delete": "Delete",
                    "reset": "Reset",
                    "update and back": "Update and go back"
                },
                "save": "Save",
                "confirm": "Confirm",
                "move": "Move",
                "breadcrumb": {
                    "home": "Home"
                },
                "title": {
                    "translatable_fields": "Translatable fields",
                    "non_translatable_fields": "Non translatable fields",
                    "create_resource": "Create {name}",
                    "edit_resource": "Edit {name}"
                },
                "general": {
                    "available_keyboard_shortcuts": "Available keyboard shortcuts on this page",
                    "view_website": "View Website",
                    "complete_your_profile": "Complete your profile",
                    "profile": "Profile",
                    "sign_out": "Sign out"
                },
                "messages": {
                    "resource_created": "{name} successfully created.",
                    "resource_not_found": "{name} not found.",
                    "resource_updated": "{name} successfully updated.",
                    "resource_deleted": "{name} successfully deleted."
                },
                "back": "Back",
                "back_to_index": "Back to {name} index",
                "permission_denied": "Permission denied. (required permission: \"{permission}\")",
                "list_resource": "List {name}",
                "create_resource": "Create {name}",
                "edit_resource": "Edit {name}",
                "destroy_resource": "Delete {name}",
                "error_token_mismatch": "Your session timed out, please submit the form again.",
                "error_404": "404",
                "error_404_title": "Oops! This page was not found.",
                "error_404_description": "The page you are looking for was not found.",
                "error_500": "500",
                "error_500_title": "Oops! Something went wrong",
                "error_500_description": "An administrator was notified.",
                "delete_cancelled": "Delete cancelled",
                "unauthorized": "Unauthorized",
                "unauthorized-access": "You do not have the appropriate permissions to access that page.",
                "unauthenticated": "Niet ingelogd",
                "unauthenticated-access": "U moet inloggen om toegang te krijgen tot deze pagina",
                "something_went_wrong": "Whoops! Something went wrong.",
                "mark_as_online": "Mark as online",
                "mark_as_offline": "Mark as offline",
                "back_to_backend": "Back to backend"
            },
            "navigation": {
                "top": {
                    "backend": "Backend",
                    "userbackend": "User-Backend",
                    "logout": "Logout",
                    "hello": "Hello"
                },
                "primary": {
                    "start": "Start",
                    "space": "My Space",
                    "login": "Login",
                    "logout": "Logout",
                    "register": "Register"
                }
            }
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "backend": {
            "page-exception": {
                "general": {
                    "back-button": "Back to all exceptions",
                    "truncate-exceptions": "Truncate exceptions table"
                },
                "index": {
                    "headline": "List Exceptions",
                    "title": "List Exceptions",
                    "subtitle": ""
                },
                "show": {
                    "headline": "Show Exception",
                    "title": "Show Exception",
                    "subtitle": "",
                    "content": {
                        "class": {
                            "label": "Class"
                        },
                        "file": {
                            "label": "File"
                        },
                        "code": {
                            "label": "Code"
                        },
                        "status_code": {
                            "label": "Status Code"
                        },
                        "line": {
                            "label": "Line"
                        },
                        "method": {
                            "label": "Method"
                        },
                        "user": {
                            "label": "User"
                        },
                        "message": {
                            "label": "Message"
                        },
                        "trace": {
                            "label": "Trace"
                        },
                        "input_data": {
                            "label": "Input Data"
                        }
                    }
                },
                "datatable": {
                    "columns": {
                        "class": "Class",
                        "message": "Message"
                    }
                }
            },
            "page-job": {
                "general": {
                    "back-button": "Back to all jobs",
                    "create-new-job": "Create new job",
                    "truncate-jobs": "Truncate jobs table"
                },
                "index": {
                    "headline": "List Jobs",
                    "title": "List Jobs",
                    "subtitle": ""
                },
                "create": {
                    "headline": "Create Job",
                    "title": "Create Job",
                    "subtitle": "",
                    "content": {
                        "type": {
                            "label": "Type"
                        },
                        "users": {
                            "label": "Users"
                        },
                        "title": {
                            "label": "Title",
                            "placeholder": "New update available"
                        },
                        "message": {
                            "label": "Message",
                            "placeholder": "Hey girls and boys, here is an update..."
                        }
                    }
                },
                "show": {
                    "headline": "Show Job",
                    "title": "Show Job",
                    "subtitle": ""
                },
                "datatable": {
                    "columns": {
                        "payload": "Name",
                        "queue": "Queue",
                        "attempts": "Attempts",
                        "reserved_at": "Reserved at",
                        "available_at": "Available_at",
                        "created_at": "created_at"
                    }
                }
            },
            "space-page-media": {
                "general": {
                    "back-button": "Back to all media",
                    "create-user": "Create Media",
                    "sizes": {
                        "original": "Original",
                        "large": "Large",
                        "medium": "Medium",
                        "small": "Small",
                        "thumb": "Thumb"
                    },
                    "form": {
                        "action": "Action",
                        "image_size": "Image Size",
                        "path": "Path",
                        "select_image": "Select image"
                    }
                },
                "index": {
                    "headline": "List Media",
                    "title": "List Media",
                    "subtitle": "",
                    "no-images-found": "No files found...",
                    "path-deletion-disabled": "This path cannot be deleted because of existing files and/or sub-paths!",
                    "path-deletion-failed": "This path could not be deleted!",
                    "notifications": {
                        "media": {
                            "deletion-successful": "Media was successfully deleted!",
                            "deletion-failed": "Media deletion failed!"
                        },
                        "path": {
                            "creation-successful": "Path was created successfully!",
                            "creation-failed": "Path creation failed!",
                            "deletion-successful": "Path was successfully deleted!",
                            "deletion-failed": "Path deletion failed!"
                        }
                    }
                },
                "create": {
                    "headline": "Create Media",
                    "title": "Create Media",
                    "subtitle": "",
                    "content": {
                        "test": {
                            "label": "test",
                            "placeholder": ""
                        }
                    }
                },
                "edit": {
                    "headline": "Edit Media",
                    "title": "Edit Media",
                    "subtitle": ""
                },
                "modals": {
                    "upload-media": {
                        "title": "Upload media",
                        "drop-area-title": "Drag & drop files here...",
                        "errors": {
                            "maximum_file_size_exceeded": "This file is to large! The maximum size is",
                            "wrong_file_type": "This file type is not allowed!",
                            "file_missing": "There was no file uploaded!"
                        },
                        "maximum-filesize-title": "The maximum file size is",
                        "allowed-filetypes-title": "The following file types are supported"
                    },
                    "move-media": {
                        "title": "Move media",
                        "content": {
                            "move_to_path": "Move to path...",
                            "error_title": "Media could not be moved!",
                            "error_message": "Please contact the site administrator...",
                            "success_title": "Media was moved successfully!"
                        }
                    },
                    "browse-media": {
                        "title": "Browse Media",
                        "content": []
                    },
                    "edit-media": {
                        "title": "Edit media",
                        "content": {
                            "name": "Name"
                        }
                    },
                    "create-path": {
                        "title": "Create path",
                        "content": {
                            "create_in_path": {
                                "label": "Create in path...",
                                "create_in_root": "Create in root"
                            },
                            "path": {
                                "label": "Path"
                            }
                        },
                        "errors": {
                            "path-exists-title": "This path already exists!",
                            "path-exists-message": "Please choose another name...",
                            "path-field-error": "This path already exists!"
                        }
                    }
                }
            },
            "page-lock": {
                "index": {
                    "headline": "Data is locked!",
                    "title": "Damn it...",
                    "subtitle": "This data is currently being edited by:",
                    "video_error_message": "Your Browser doesn't support this tag!"
                }
            },
            "header": {
                "left-nav": {
                    "to-frontend": "To Frontend",
                    "to-backend": "Zum Admin-Backend"
                },
                "right-nav": {
                    "no-notifications": "No new notifications!"
                },
                "quickinfo_bar": {
                    "revision": {
                        "title": "Revisions"
                    },
                    "exception": {
                        "title": "Exceptions"
                    }
                }
            },
            "page-dashboard": {
                "widgets": {
                    "sample-widget": {
                        "title": "Traffic",
                        "top-buttons": {
                            "day": "Day",
                            "month": "Month",
                            "year": "Year"
                        },
                        "footer": {
                            "visits": "Visits",
                            "unique": "Unique",
                            "pageviews": "Pageviews",
                            "new_users": "New Users",
                            "bounce_rate": "Bounce Rate"
                        }
                    }
                }
            },
            "page-setting": {
                "general": {
                    "back-button": "Back to all exceptions",
                    "truncate-exceptions": "Truncate exceptions table"
                },
                "index": {
                    "headline": "Settings",
                    "title": "Settings",
                    "subtitle": "",
                    "tabs": {
                        "tab-login-register": {
                            "title": "Login & Register",
                            "content": {
                                "enable_login": {
                                    "label": "Enable Login"
                                },
                                "enable_sub_users": {
                                    "label": "Enable Sub-Users"
                                },
                                "enable_registration": {
                                    "label": "Enable Registration"
                                },
                                "default_registration_role": {
                                    "label": "Default Registration Role"
                                },
                                "enable_password_strength_validation": {
                                    "label": "Validate Password"
                                },
                                "password_strength_validation_rules": {
                                    "label": "Passwort Validation-Rules"
                                }
                            }
                        },
                        "tab-maintenance": {
                            "title": "Maintenance",
                            "content": {
                                "enable_maintenance": {
                                    "label": "Enable Maintenance-Mode"
                                },
                                "caches": {
                                    "clear-cache": {
                                        "label": "Clear Cache"
                                    },
                                    "clear-view": {
                                        "label": "Clear View"
                                    },
                                    "clear-config": {
                                        "label": "Clear Config"
                                    },
                                    "cache-config": {
                                        "label": "Cache Config"
                                    },
                                    "clear-route": {
                                        "label": "Clear Route"
                                    },
                                    "cache-route": {
                                        "label": "Cache Route"
                                    }
                                }
                            }
                        },
                        "tab-api": {
                            "title": "API",
                            "content": {
                                "enable_api": {
                                    "label": "Activate API"
                                }
                            }
                        },
                        "tab-media": {
                            "title": "Media",
                            "content": {
                                "maximum_upload_size_in_mb": {
                                    "label": "Maximum upload size (mb)"
                                }
                            }
                        }
                    }
                }
            },
            "page-permission": {
                "general": {
                    "back-button": "Back to all permissions",
                    "create-role": "Create Permission"
                },
                "index": {
                    "headline": "List Permissions",
                    "title": "List Permissions",
                    "subtitle": "",
                    "tabs": {
                        "tab-role": {
                            "title": "Permission",
                            "content": {
                                "test": {
                                    "label": "test"
                                }
                            }
                        },
                        "tab-permission": {
                            "title": "Permissions",
                            "content": {
                                "test": {
                                    "label": "test"
                                }
                            }
                        }
                    }
                },
                "create": {
                    "headline": "Create Permission",
                    "title": "Create Permission",
                    "subtitle": "",
                    "content": {
                        "name": {
                            "label": "Internal Name",
                            "placeholder": "editor"
                        },
                        "display_name": {
                            "label": "Displayed Name",
                            "placeholder": "Editor"
                        },
                        "description": {
                            "label": "Description",
                            "placeholder": "This user can..."
                        },
                        "group_identifier": {
                            "label": "Group",
                            "placeholder": "user"
                        }
                    }
                },
                "edit": {
                    "headline": "Edit Permission",
                    "title": "Edit Permission",
                    "subtitle": ""
                },
                "datatable": {
                    "columns": {
                        "name": "Slug",
                        "display_name": "Title"
                    }
                }
            },
            "page-user": {
                "general": {
                    "back-button": "Back to all users",
                    "create-user": "Create User"
                },
                "index": {
                    "headline": "List Users",
                    "title": "List Users",
                    "subtitle": "",
                    "tabs": {
                        "tab-user": {
                            "title": "User"
                        }
                    }
                },
                "create": {
                    "headline": "Create User",
                    "title": "Create User",
                    "subtitle": "",
                    "content": {
                        "skip_activation": {
                            "label": "Skip Activation",
                            "placeholder": ""
                        },
                        "parent_user_id": {
                            "label": "Parent User",
                            "placeholder": ""
                        },
                        "account_type": {
                            "label": "Account Type",
                            "placeholder": ""
                        },
                        "username": {
                            "label": "Username",
                            "placeholder": "john1992"
                        },
                        "firstname": {
                            "label": "Firstname",
                            "placeholder": "John"
                        },
                        "lastname": {
                            "label": "Users",
                            "placeholder": "Doe"
                        },
                        "email": {
                            "label": "E-Mail",
                            "placeholder": "john.doe@domain.com"
                        },
                        "roles": {
                            "label": "Roles"
                        },
                        "password": {
                            "label": "Password"
                        },
                        "repeat_password": {
                            "label": "Repeat Password"
                        }
                    }
                },
                "edit": {
                    "headline": "Edit User",
                    "title": "Edit User",
                    "subtitle": "",
                    "api_token_button": "Renew token",
                    "api_token_flash": "API-Token was successfully renewed!"
                },
                "delete": {
                    "error": "You can not delete your own user!"
                },
                "datatable": {
                    "columns": {
                        "firstname": "Firstname",
                        "lastname": "Lastname",
                        "email": "E-Mail",
                        "roles": "Roles"
                    }
                }
            },
            "page-role": {
                "general": {
                    "back-button": "Back to all roles",
                    "create-role": "Create Role"
                },
                "index": {
                    "headline": "List Roles",
                    "title": "List Roles",
                    "subtitle": "",
                    "tabs": {
                        "tab-role": {
                            "title": "Role",
                            "content": {
                                "test": {
                                    "label": "test"
                                }
                            }
                        },
                        "tab-permission": {
                            "title": "Permissions",
                            "content": {
                                "test": {
                                    "label": "test"
                                }
                            }
                        }
                    }
                },
                "create": {
                    "headline": "Create Role",
                    "title": "Create Role",
                    "subtitle": "",
                    "content": {
                        "name": {
                            "label": "Internal Name",
                            "placeholder": "editor"
                        },
                        "display_name": {
                            "label": "Displayed Name",
                            "placeholder": "Editor"
                        },
                        "description": {
                            "label": "Description",
                            "placeholder": "This user can..."
                        }
                    }
                },
                "edit": {
                    "headline": "Edit Role",
                    "title": "Edit Role",
                    "subtitle": ""
                },
                "datatable": {
                    "columns": {
                        "name": "Slug",
                        "display_name": "Title"
                    }
                }
            },
            "page-revision": {
                "general": {
                    "back-button": "Back to all revisions",
                    "truncate-revisions": "Truncate revisions table"
                },
                "index": {
                    "headline": "List Revisions",
                    "title": "List Revisions",
                    "subtitle": ""
                },
                "show": {
                    "headline": "Show Revision",
                    "title": "Show Revision",
                    "subtitle": "",
                    "content": {
                        "revisionable_id": {
                            "label": "Revisionable ID"
                        },
                        "revisionable_type": {
                            "label": "Revisionable Type"
                        },
                        "user": {
                            "label": "User"
                        },
                        "key": {
                            "label": "Key"
                        },
                        "old_value": {
                            "label": "Old Value"
                        },
                        "new_value": {
                            "label": "New Value"
                        }
                    }
                },
                "datatable": {
                    "columns": {
                        "revisionable_id": "Rev-ID",
                        "revisionable_type": "Rev-Type",
                        "user": "User-ID",
                        "key": "Key",
                        "old_value": "Old Value",
                        "new_value": "New Value"
                    }
                }
            },
            "core": {
                "modal": {
                    "title": "Confirmation",
                    "confirmation-message": "Are you sure you want to delete this record?"
                },
                "table": {
                    "created_at": "Created at",
                    "actions": "Actions",
                    "thumbnail": "Thumbnail"
                },
                "tab": {
                    "english": "English",
                    "french": "French",
                    "dutch": "Dutch",
                    "italian": "Italian",
                    "greek": "Ελληνικά",
                    "spanish": "Spanish",
                    "polish": "Polish",
                    "portuguese": "Portuguese",
                    "arabic": "Arabic",
                    "macedonian": "Македонски",
                    "turkish": "Türkçe",
                    "czech": "Czech",
                    "swedish": "Svenska",
                    "korean": "Korean"
                },
                "button": {
                    "cancel": "Cancel",
                    "create": "Create",
                    "update": "Update",
                    "delete": "Delete",
                    "reset": "Reset",
                    "update and back": "Update and go back"
                },
                "save": "Save",
                "confirm": "Confirm",
                "move": "Move",
                "breadcrumb": {
                    "home": "Home"
                },
                "title": {
                    "translatable_fields": "Translatable fields",
                    "non_translatable_fields": "Non translatable fields",
                    "create_resource": "Create {name}",
                    "edit_resource": "Edit {name}"
                },
                "general": {
                    "available_keyboard_shortcuts": "Available keyboard shortcuts on this page",
                    "view_website": "View Website",
                    "complete_your_profile": "Complete your profile",
                    "profile": "Profile",
                    "sign_out": "Sign out"
                },
                "messages": {
                    "resource_created": "{name} successfully created.",
                    "resource_not_found": "{name} not found.",
                    "resource_updated": "{name} successfully updated.",
                    "resource_deleted": "{name} successfully deleted."
                },
                "back": "Back",
                "back_to_index": "Back to {name} index",
                "permission_denied": "Permission denied. (required permission: \"{permission}\")",
                "list_resource": "List {name}",
                "create_resource": "Create {name}",
                "edit_resource": "Edit {name}",
                "destroy_resource": "Delete {name}",
                "error_token_mismatch": "Your session timed out, please submit the form again.",
                "error_404": "404",
                "error_404_title": "Oops! This page was not found.",
                "error_404_description": "The page you are looking for was not found.",
                "error_500": "500",
                "error_500_title": "Oops! Something went wrong",
                "error_500_description": "An administrator was notified.",
                "delete_cancelled": "Delete cancelled",
                "unauthorized": "Unauthorized",
                "unauthorized-access": "You do not have the appropriate permissions to access that page.",
                "unauthenticated": "Niet ingelogd",
                "unauthenticated-access": "U moet inloggen om toegang te krijgen tot deze pagina",
                "something_went_wrong": "Whoops! Something went wrong.",
                "mark_as_online": "Mark as online",
                "mark_as_offline": "Mark as offline",
                "back_to_backend": "Back to backend"
            },
            "sidebar": {
                "headers": {
                    "personal": "Personal",
                    "administration": "Administration"
                },
                "dashboard": {
                    "label": "Dashboard"
                },
                "users": {
                    "label": "Users",
                    "submenu": {
                        "list": "List Users",
                        "create": "Create Users"
                    }
                },
                "roles": {
                    "label": "Roles",
                    "submenu": {
                        "list": "List Roles",
                        "create": "Create Role"
                    }
                },
                "media": {
                    "label": "Media",
                    "submenu": {
                        "list": "List Media",
                        "create": "Upload Media"
                    }
                },
                "permissions": {
                    "label": "Permissions",
                    "submenu": {
                        "list": "List Permissions",
                        "create": "Create Permissions"
                    }
                },
                "jobs": {
                    "label": "Job Queue",
                    "submenu": {
                        "list": "List Jobs",
                        "create": "Create Job"
                    }
                },
                "exceptions": {
                    "label": "Exceptions",
                    "submenu": {
                        "list": "List Exceptions"
                    }
                },
                "revisions": {
                    "label": "Revisions",
                    "submenu": {
                        "list": "List Revisions"
                    }
                },
                "notifications": {
                    "label": "Notifications",
                    "submenu": {
                        "list": "List Notifications",
                        "create": "Create Notification"
                    }
                },
                "profile": {
                    "label": "My Profile",
                    "submenu": {
                        "edit": "Edit Profile"
                    }
                },
                "setting": {
                    "label": "Settings"
                }
            },
            "modal": {
                "notification": {
                    "title": "You have new notifications!",
                    "body": {
                        "line1": "Please take time to read your notifications, it may be important.",
                        "line2": "Also make sure to mark alreay read notifications as read!"
                    }
                },
                "confirm": {
                    "title": "Are you sure about this?",
                    "body": {
                        "text": "This action cannot be undone!"
                    },
                    "buttons": {
                        "confirm": "Proceed",
                        "cancel": "Cancel"
                    }
                }
            }
        },
        "datatable": {
            "buttons": {
                "copy": "Copy",
                "print": "Print"
            }
        },
        "routes": {
            "product": {
                "index": "products",
                "show": "/product/{id}/{slug}"
            },
            "manufacturer": {
                "index": "manufacturers",
                "show": "/manufacturer/{id}/{slug}"
            },
            "lesson": {
                "index": "lessons",
                "show": "/lesson/{id}/{slug}"
            }
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        },
        "general": {
            "all": "All",
            "general": "General",
            "primary": "Primary",
            "secondary": "Secondary",
            "sub": "Sub",
            "new": "New",
            "default": "Default",
            "info": "Info",
            "warning": "Warning",
            "success": "Success",
            "actions": "Actions",
            "read_more": "Read more",
            "account": "Account",
            "profile": "Profile",
            "settings": "Settings",
            "logout": "Logout",
            "notifications": "Notifications",
            "save": "Save",
            "create": "Create",
            "update": "Update",
            "delete": "Delete",
            "language": "Language",
            "created_at": "Created at",
            "updated_at": "Updated at",
            "delete-row-button": "Delete selected rows?",
            "notification_email": {
                "whoops": "Whoops!",
                "hello": "Hello!",
                "regards": "Regards",
                "subcopy_line1": "If you’re having trouble clicking the",
                "subcopy_line2": "button, copy and paste the URL below into your web browser: [{actionURL}]({actionURL})"
            },
            "required_field": "Required",
            "cleared_successfully": "Successfully cleared!",
            "deleted_successfully": "Successfully deleted!",
            "truncated_successfully": "Truncated successfully!",
            "success_created_title": "Successfully created!",
            "success_created_subtitle": "You will be redirected in a few moments...",
            "success_update_title": "Successfully updated!",
            "technical_error_title": "There was a technical error!",
            "technical_error_text": "Please contact the administrator or try again later...",
            "error_title": "Please check all fields!",
            "error_text": "Then send the form again...",
            "cronjob_successful": "Cronjob was successful!",
            "cronjob_failed": "Cronjob failed!",
            "characters_remaining": "characters remaining",
            "error_pages": {
                "503": {
                    "title": "503 - Site not available!",
                    "text": "We are having a little break..."
                },
                "403": {
                    "title": "403 - No access here!",
                    "text": "Continue driving..."
                },
                "404": {
                    "title": "404 - Page Not Found",
                    "text": "Seem's that you are lost..."
                }
            },
            "google": {
                "analytics_deactivated": "Google Analytics Tracking was deactivated for this Website."
            }
        },
        "media": {
            "folders": {
                "list resource": "List folder",
                "create resource": "New folder",
                "edit resource": "Edit folder",
                "destroy resource": "Delete folder",
                "folder was created": "Folder was successfully created",
                "folder was updated": "Folder was successfully updated",
                "folder name": "Folder name"
            },
            "media": {
                "title": {
                    "media": "Media",
                    "edit media": "Edit media"
                },
                "breadcrumb": {
                    "media": "Media"
                },
                "table": {
                    "filename": "Filename",
                    "width": "Width",
                    "height": "Height"
                },
                "form": {
                    "alt_attribute": "Alt attribute",
                    "description": "Description",
                    "keywords": "Keywords"
                },
                "validation": {
                    "max_size": "Maximum folder size ({size}) of the Media folder reached."
                },
                "file-sizes": {
                    "B": "Bytes",
                    "KB": "Kb",
                    "MB": "Mb",
                    "GB": "Gb",
                    "TB": "Tb"
                },
                "choose file": "Choose a file",
                "insert": "Insert this file",
                "file picker": "File Picker",
                "Browse": "Browse ...",
                "upload file": "Upload a file",
                "remove media": "Remove media",
                "list resource": "List media",
                "create resource": "Create media",
                "edit resource": "Edit media",
                "destroy resource": "Delete media",
                "file too large": "File is too large. Must be below {size} MB.",
                "some files not moved": "Some files were not moved",
                "files moved successfully": "Files moved successfully"
            },
            "messages": {
                "file updated": "File updated",
                "file deleted": "File deleted",
                "folder updated": "Folder updated",
                "folder deleted": "Folder deleted",
                "selected items deleted": "The selected item(s) were deleted"
            }
        }
    }
}
