var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.tokens.length > 0
      ? _c("div", [
          _c("div", { staticClass: "card card-default" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._v("Authorized Applications")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "table",
                { staticClass: "table table-striped table-condensed mb-0" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.tokens, function(token) {
                      return _c("tr", [
                        _c(
                          "td",
                          { staticStyle: { "vertical-align": "middle" } },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(token.client.name) +
                                "\n                            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticStyle: { "vertical-align": "middle" } },
                          [
                            token.scopes.length > 0
                              ? _c("span", [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(token.scopes.join(", ")) +
                                      "\n                                "
                                  )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticStyle: { "vertical-align": "middle" } },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "action-link text-danger",
                                on: {
                                  click: function($event) {
                                    return _vm.revoke(token)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "userbackend.space-page-profile.tabs.tab-api.content.authorized_clients.revoke"
                                      )
                                    ) +
                                    "\n                                    Revoke\n                                "
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Scopes")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }