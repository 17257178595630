'use strict';

var module_frontend = {
    // Code
};


$(document).ready(function () {
    // Code
});
