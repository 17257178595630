'use strict';

//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import ClassicEditor from '@custom-ckeditor/ckeditor';
//import ClassicEditor from './ckeditor5-classic';

//window.ClassicEditor = ClassicEditor;

var general_js = {
/*
    classicEditor: function() {
        ClassicEditor
            .create( document.querySelector( ".ClassicEditor" ) )
            .catch( error => {
                console.error( error );
            } );
    },
    */

    preSelectTab: function() {
        var hash = window.location.hash;

        if (hash.length > 0 && $('ul.tabs-preselectable').length === 1) {
            hash && $('ul.tabs-preselectable a[href="' + hash + '"]').tab('show');
        }
    },

    fadeOut: function (el) {
        el.style.opacity = 1;

        (function fade() {
            if ((el.style.opacity -= .1) < 0) {
                el.style.display = "none";
            } else {
                requestAnimationFrame(fade);
            }
        })();
    },

    fadeIn: function (el, display) {
        el.style.opacity = 0;
        el.style.display = display || "block";

        (function fade() {
            var val = parseFloat(el.style.opacity);
            if (!((val += .1) > 1)) {
                el.style.opacity = val;
                requestAnimationFrame(fade);
            }
        })();
    },

    domElements: {
        sidebar_navigation: $('nav > ul.nav'),
        panelIconOpened: 'fal fa-arrow-up',
        panelIconClosed: 'fal fa-arrow-down',
    },

    siteSettings: {
        colors: {},
        ajaxLoad: false
    },

    aosInit: function () {
        AOS.init({
            disable: 'mobile',
            once: true
        });
    },

    flashAlert: function () {
        $('body').find('.flash-message').not('.alert-important').delay(4000).slideUp(350);
    },

    initSiteSettings: function () {
        //Default colours
        general_js.siteSettings.colors.brandPrimary = '#20a8d8';
        general_js.siteSettings.colors.brandSuccess = '#4dbd74';
        general_js.siteSettings.colors.brandInfo = '#63c2de';
        general_js.siteSettings.colors.brandWarning = '#f8cb00';
        general_js.siteSettings.colors.brandDanger = '#f86c6b';

        general_js.siteSettings.colors.grayDark = '#2a2c36';
        general_js.siteSettings.colors.gray = '#55595c';
        general_js.siteSettings.colors.grayLight = '#818a91';
        general_js.siteSettings.colors.grayLighter = '#d1d4d7';
        general_js.siteSettings.colors.grayLightest = '#f8f9fa';
    },

    select2: function () {
        $(document).find(".select2").select2();
    },

    resizeSelect2: function () {
        $(".select2").each(function () {
            $(this).attr('style', 'width: 100%');
        });
    },

    confirmAction: function () {
        $(document).on('click', '.confirm-action', function (e) {
            e.preventDefault();

            var redirect = '';
            var that = $(this);

            if ($(this).attr('href')) {
                redirect = $(this).attr('href');
            }

            $.confirm({
                icon: 'fal fa-exclamation-triangle',
                closeIcon: false,
                escapeKey: true,
                backgroundDismiss: true,
                columnClass: 'medium/m',
                containerFluid: false,
                title: $(that).attr('data-title') ? $(that).attr('data-title') : Laravel.trans.modal.confirm.title,
                content: $(that).attr('data-content') ? $(that).attr('data-content') : Laravel.trans.modal.confirm.body.text,
                type: 'red',
                typeAnimated: true,
                theme: 'supervan',
                draggable: false,
                buttons: {
                    confirm: {
                        text: Laravel.trans.modal.confirm.buttons.confirm,
                        btnClass: 'btn-success',
                        action:
                            function () {
                                if (redirect !== '') {
                                    window.location = redirect;
                                }
                                else {
                                    that.parents('form').submit();
                                }
                            }
                    },
                    cancel: {
                        btnClass: 'btn-danger',
                        text: Laravel.trans.modal.confirm.buttons.cancel,
                    },
                }
            });
        });
    },

    ajaxForm: function () {
        $('body').on('submit', 'form.ajax-form', function (e) {
            e.preventDefault();

            general_js.showLoader();

            if (typeof(CKEDITOR) !== "undefined") {
                var instance;
                for (instance in CKEDITOR.instances) {
                    CKEDITOR.instances[instance].updateElement();
                }
            }

            var form = $(this);
            var formData = form.serializeJSON();
            var formResult = form.find('.form-result');
            var formMethod = form.attr('method');
            var submitButton = form.find('[type="submit"]');

            var method = $('_method');

            if (method.length) {
                formMethod = method.val()
            }

            $.ajaxSetup({headers: {'X-CSRF-TOKEN': Laravel.csrfToken}});

            $('.invalid-feedback').slideUp().remove();
            $('.form-group').removeClass('is-invalid');
            $('.input-group').removeClass('is-invalid');
            $('.cke').removeClass('is-invalid');
            $('input, select').removeClass('is-invalid');
            $('input, select, .select2-selection').removeClass('is-invalid-select2');

            submitButton.attr('disabled', '');

            var request = $.ajax({
                type: formMethod,
                dataType: 'json',
                url: form.attr('action'),
                data: formData,
                /*
                error: function (xhr, desc, e) {
                    formResult
                        .slideDown()
                        .removeClass('open-alert')
                        .removeClass('bg-danger')
                        .removeClass('bg-success')
                        .addClass('bg-danger')
                        .html('<h2 style="margin: 0; color: #fff;">' + Laravel.trans.form.technical_error_title + '<br /> <small>' + Laravel.trans.form.technical_error_text + '</small></h2>');
                }
                */
            });


            request.done(function (data) {

                if (data.success === true) {

                    var successTitle = data.successTitle !== undefined ? data.successTitle : Laravel.trans.form.success_update_title;

                    formResult
                        .removeClass('open-alert')
                        .removeClass('bg-danger')
                        .removeClass('bg-success')
                        .addClass('bg-success')
                        .slideDown()
                        .html('<h2 style="margin: 0; color: #fff;"><i class="fa fa-save"></i> ' + successTitle + '</h2>');


                    var resetAfterSubmitTime = 4000;
                    if (data.resetAfterSubmitTime) {
                        resetAfterSubmitTime = data.resetAfterSubmitTime;
                    }

                    setTimeout(function () {
                        formResult
                            .slideUp()
                            .removeClass('open-alert')
                            .removeClass('bg-danger')
                            .removeClass('bg-success')
                            .html('');

                        if (form.hasClass('resetAfterSubmit') || data.resetAfterSubmit && data.resetAfterSubmit === true) {
                            form.trigger("reset");
                            form[0].reset();
                        }

                    }, resetAfterSubmitTime);


                    $('html, body').animate({scrollTop: form.find('.form-result').offset().top - 300}, 1000);

                    if (data.redirect) {
                        var redirectTime = 3000;

                        if (data.redirectTime) {
                            redirectTime = data.redirectTime;
                        }

                        setTimeout(function () {
                            window.location = data.redirect;
                        }, redirectTime);
                    }
                }

                submitButton.removeAttr('disabled');
                general_js.hideLoader();
            });

            request.fail(function (jqXHR, textStatus, error) {
                var errors = jqXHR.responseJSON;

                if (errors.custom_error) {
                    $.each(errors, function (i, v) {
                        var msg = '<span class="invalid-feedback"><strong>' + v + '</strong></span>';

                        if (i.split('.').length >= 3) {
                            var partials = i.split('.');
                            i = partials[0] + '[' + partials[1] + ']' + '[' + partials[2] + ']';
                        }

                        if(  $('input[name="' + i + '"], textarea[name="' + i + '"]').hasClass('custom-ckeditor') ) {
                            $('input[name="' + i + '"], textarea[name="' + i + '"]')
                                .addClass('is-invalid')
                                .parent('.form-group')
                                .addClass('is-invalid').find('.cke').addClass('is-invalid').after(msg);
                        }

                        if(  $('input[name="' + i + '"], textarea[name="' + i + '"]').parent('.form-group').length && !$('input[name="' + i + '"], textarea[name="' + i + '"]').hasClass('custom-ckeditor') ) {
                            $('input[name="' + i + '"], textarea[name="' + i + '"]')
                                .after(msg)
                                .addClass('is-invalid')
                                .parent('.form-group')
                                .addClass('is-invalid');
                        }


                        if( $('input[name="' + i + '"], textarea[name="' + i + '"]').parent('.input-group').length ) {
                            $('input[name="' + i + '"], textarea[name="' + i + '"]')
                                .addClass('is-invalid')
                                .parent('.input-group')
                                .addClass('is-invalid')
                                .after(msg);
                        }



                        if ($('select[name="' + i + '[]"]').hasClass('select2')
                        ) {
                            $('select[name="' + i + '[]"]')
                                .addClass('is-invalid')
                                .parent('.form-group')
                                .addClass('is-invalid')
                                .find('.select2-container')
                                .after(msg);

                            $('select[name="' + i + '[]"]')
                                .parent('.form-group')
                                .find('.select2-selection')
                                .addClass('is-invalid-select2');
                        }

                        if ($('select[name="' + i + '"]').hasClass('select2')
                        ) {
                            $('select[name="' + i + '"]')
                                .addClass('is-invalid')
                                .parent('.form-group')
                                .addClass('is-invalid')
                                .find('.select2-container')
                                .after(msg);

                            $('select[name="' + i + '"]')
                                .parent('.form-group')
                                .find('.select2-selection')
                                .addClass('is-invalid-select2');
                        }
                    });

                    var keys = Object.keys(errors);
                    var set_focus = $('input[name="' + keys[0] + '"], select[name="' + keys[0] + '"], textarea[name="' + keys[0] + '"]');

                    var custom_error = '<h2 style="margin: 0;">' + Laravel.trans.form.error_title + '</h2>' +
                        '<p style="margin-bottom: 0;">' + Laravel.trans.form.error_text + '</p>';


                    if (errors.custom_error) {
                        custom_error = '<h2 style="margin: 0;">' + errors.custom_error.errorTitle + '</h2>' +
                            '<p style="margin-bottom: 0;">' + errors.custom_error.errorMessage + '</p>';
                    }

                    formResult
                        .addClass('open-alert')
                        .addClass('alert-important')
                        .addClass('bg-danger')
                        .slideDown()
                        .html(custom_error);

                    /*
                    $('html, body').animate({scrollTop: form.find('.form-result').offset().top}, 1000, function () {
                        set_focus.focus()
                    });
                    */
                    set_focus.focus()

                }
                submitButton.removeAttr('disabled');
                general_js.hideLoader();
            });

            submitButton.removeAttr('disabled');
            general_js.hideLoader();
        });

    },

    showLoader: function () {
        var loader = document.getElementById('ajax-loader');
        var body = document.body;

        body.classList.remove('lock-body');
        body.classList.add('lock-body');

        general_js.fadeIn(loader);
    },

    hideLoader: function () {
        var loader = document.getElementById('ajax-loader');
        var body = document.body;

        body.classList.remove('lock-body');

        general_js.fadeOut(loader);
    },

    allowNumericWithoutDecimal: function () {
        $(".allownumericwithoutdecimal").on("keypress keyup blur", function (event) {
            $(this).val($(this).val().replace(/[^\d].+/, ""));
            if ((event.which < 48 || event.which > 57) && event.which !== 8) {
                event.preventDefault();
            }
        });
    },

    allowNumericWithDecimal: function () {
        $(".allownumericwithdecimal").on("keypress keyup blur", function (event) {
            $(this).val($(this).val().replace(/[^0-9\.]/g, ''));
            if ((event.which !== 46 || $(this).val().indexOf('.') !== -1) && (event.which < 48 || event.which > 57) && event.which !== 8) {
                event.preventDefault();
            }
        });
    },

    tooltip: function () {
        /*
        $(document).find('[data-toggle="tooltip"]').tooltip({
            container: 'body'
        });
        */

        $('body').tooltip({
            selector: '[data-toggle="tooltip"]'
        });
    },

    tablesorter: function () {
        $(".tablesorter").tablesorter();
    },

    disableLinks: function () {
        $(document).on('click', 'a', function (event) {
            if ($(this).hasClass("disabled")) {
                event.preventDefault();
            }
        });
    },

    matchHeightInit: function () {
        $('.eq-height').matchHeight({
            byRow: true,
            property: 'height',
            target: null,
            remove: false
        });
    },

    mainNavigation: function (e) {
        $('nav > ul.nav').find('a').each(function () {

            var cUrl = String(window.location).split('?')[0];

            if (cUrl.substr(cUrl.length - 1) == '#') {
                cUrl = cUrl.slice(0, -1);
            }

            if ($($(this))[0].href == cUrl) {
                $(this).addClass('active');

                $(this).parents('ul').add(this).each(function () {
                    $(this).parent().addClass('open');
                });
            }
        });

        $('nav > ul.nav').on('click', 'a', function (e) {
            if (general_js.siteSettings.ajaxLoad) {
                e.preventDefault();
            }

            if ($(this).hasClass('nav-dropdown-toggle')) {
                $(this).parent().toggleClass('open');
                general_js.resizeBroadcast()
            }
        });
    },

    resizeBroadcast: function () {

        var timesRun = 0;
        var interval = setInterval(function () {
            timesRun += 1;
            if (timesRun === 5) {
                clearInterval(interval);
            }
            window.dispatchEvent(new Event('resize'));
        }, 62.5);
    },

    disableMovingToTop: function () {
        $('a[href="#"][data-top!=true]').click(function (e) {
            e.preventDefault();
        });
    },

    cardActions: function () {
        $('.card-actions').on('click', 'a, button', function (e) {
            e.preventDefault();

            if ($(this).hasClass('btn-close')) {
                $(this).parent().parent().parent().fadeOut();
            } else if ($(this).hasClass('btn-minimize')) {
                if ($(this).hasClass('collapsed')) {
                    $('i', $(this)).removeClass(general_js.domElements.panelIconOpened).addClass(general_js.domElements.panelIconClosed);
                } else {
                    $('i', $(this)).removeClass(general_js.domElements.panelIconClosed).addClass(general_js.domElements.panelIconOpened);
                }
            } else if ($(this).hasClass('btn-setting')) {
                $('#myModal').modal('show');
            }

        });
    },

    sidebarToggleSetting: function () {

        $('.site_setting_sidebar_toggle').on('click', function () {
            var old_setting_value = $(this).data('setting-value');
            var new_setting_value = old_setting_value === 1 ? 0 : 1;
            var $this = $(this);

            $.ajaxSetup({headers: {'X-CSRF-TOKEN': Laravel.csrfToken}});

            var request = $.ajax({
                type: 'post',
                dataType: 'json',
                url: route('space.set-sidebar-toggle-setting'),
                data: {setting_value: new_setting_value}
            });

            request.done(function (response) {
                if (response.success) {
                    $this.attr('data-setting-value', new_setting_value);
                    $this.data('setting-value', new_setting_value);

                    if( typeof window.LaravelDataTables !== "undefined" ) {
                        Laravel.helpers['redrawDatatableToFullwidth']();
                        window.LaravelDataTables['dataTableBuilder'].columns.adjust().draw();
                        window.LaravelDataTables['dataTableBuilder'].state.save();
                    }
                }
            });

            request.fail(function (jqXHR, textStatus, error) {
                //
            });
        });
    },

    sidebarMinimizerSetting: function () {

        $('.sidebar-minimizer').on('click', function () {
            var old_setting_value = $(this).data('setting-value');
            var new_setting_value = old_setting_value === 1 ? 0 : 1;
            var $this = $(this);

            $.ajaxSetup({headers: {'X-CSRF-TOKEN': Laravel.csrfToken}});

            var request = $.ajax({
                type: 'post',
                dataType: 'json',
                url: route('space.set-sidebar-minimizer-setting'),
                data: {setting_value: new_setting_value}
            });

            request.done(function (response) {
                if (response.success) {
                    $this.attr('data-setting-value', new_setting_value);
                    $this.data('setting-value', new_setting_value);

                    if( typeof window.LaravelDataTables !== "undefined" ) {
                        Laravel.helpers['redrawDatatableToFullwidth']();
                        window.LaravelDataTables['dataTableBuilder'].columns.adjust().draw();
                        window.LaravelDataTables['dataTableBuilder'].state.save();
                    }

                }
            });

            request.fail(function (jqXHR, textStatus, error) {
                //
            });
        });
    },

    asideToggleSetting: function () {

        $('.aside-menu-toggler').on('click', function () {
            var old_setting_value = $(this).data('setting-value');
            var new_setting_value = old_setting_value === 1 ? 0 : 1;
            var $this = $(this);

            $.ajaxSetup({headers: {'X-CSRF-TOKEN': Laravel.csrfToken}});

            var request = $.ajax({
                type: 'post',
                dataType: 'json',
                url: route('space.set-aside-toggle-setting'),
                data: {setting_value: new_setting_value}
            });

            request.done(function (response) {
                if (response.success) {
                    $this.attr('data-setting-value', new_setting_value);
                    $this.data('setting-value', new_setting_value);

                    if( typeof window.LaravelDataTables !== "undefined" ) {
                        Laravel.helpers['redrawDatatableToFullwidth']();
                        window.LaravelDataTables['dataTableBuilder'].columns.adjust().draw();
                        window.LaravelDataTables['dataTableBuilder'].state.save();
                    }
                }
            });

            request.fail(function (jqXHR, textStatus, error) {
                //
            });
        });
    },

    checkRowDelete: function () {
        $('.main').on('change', '.check_row_delete', function () {
            var $this = $(this);
            var $delete_row_button = $('#delete_row_button');

            if ($this.prop('checked')) {
                $this.prop('checked', true);
                //$delete_row_button.fadeIn();
                $delete_row_button.removeClass('disabled');
            }
            else {
                $this.prop('checked', false);
                //$delete_row_button.fadeOut();
                $delete_row_button.addClass('disabled');
            }
        });


        $('body').on('change', '#check_all_rows', function () {

            var $this = $(this);
            var $check_row_delete = $('body').find('.check_row_delete');
            var $delete_row_button = $('#delete_row_button');

            var checkbox_count = parseInt($check_row_delete.length);


            if (checkbox_count > 0) {
                $delete_row_button.fadeIn();
            }

            if ($this.prop('checked')) {
                $check_row_delete.prop('checked', true);
                $delete_row_button.removeClass('disabled');
            }
            else {
                $check_row_delete.prop('checked', false);
                //$delete_row_button.fadeOut();
                $delete_row_button.addClass('disabled');
            }
        });
    },

    tabHashNavigation: function () {
        $('.nav-tabs a').click(function (e) {
            //$(this).tab('show');
            window.location.hash = this.hash;
        });
    },

    redrawDatatableToFullwidth: function() {
        $('#dataTableBuilder').css('width', '100%');
        window.LaravelDataTables['dataTableBuilder'].state.save();
        console.log('Table redrawn');
    },

    generateSlug: function(input) {
        var result = input;

        result = result.replace(/ /g, "-").toLowerCase();
        result = result.replace(/[^a-zA-ZäöüÄÖÜß0-9-\s]/, '');
        result = result.replace(/-+/g, '-');
        result = result.replace(/_+/g, '-');

        result = general_js.replaceUmlauts(result);

        return result;
    },

    inputSlugListener: function() {
        $('#app').on('keypress keyup blur paste', '.input-slug', function() {
            var currentVal = $(this).val();
            $(this).val(general_js.generateSlug(currentVal));
        });
    },

    replaceUmlauts: function(input) {
        var diacriticsMap = [
            {'base':'A', 'letters':/[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g},
            {'base':'AA','letters':/[\uA732]/g},
            {'base':'AE','letters':/[\u00C4\u00C6\u01FC\u01E2]/g},
            {'base':'AO','letters':/[\uA734]/g},
            {'base':'AU','letters':/[\uA736]/g},
            {'base':'AV','letters':/[\uA738\uA73A]/g},
            {'base':'AY','letters':/[\uA73C]/g},
            {'base':'B', 'letters':/[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g},
            {'base':'C', 'letters':/[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g},
            {'base':'D', 'letters':/[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g},
            {'base':'DZ','letters':/[\u01F1\u01C4]/g},
            {'base':'Dz','letters':/[\u01F2\u01C5]/g},
            {'base':'E', 'letters':/[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g},
            {'base':'F', 'letters':/[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g},
            {'base':'G', 'letters':/[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g},
            {'base':'H', 'letters':/[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g},
            {'base':'I', 'letters':/[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g},
            {'base':'J', 'letters':/[\u004A\u24BF\uFF2A\u0134\u0248]/g},
            {'base':'K', 'letters':/[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g},
            {'base':'L', 'letters':/[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g},
            {'base':'LJ','letters':/[\u01C7]/g},
            {'base':'Lj','letters':/[\u01C8]/g},
            {'base':'M', 'letters':/[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g},
            {'base':'N', 'letters':/[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g},
            {'base':'NJ','letters':/[\u01CA]/g},
            {'base':'Nj','letters':/[\u01CB]/g},
            {'base':'O', 'letters':/[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g},
            {'base':'OE','letters':/[\u00D6\u0152]/g},
            {'base':'OI','letters':/[\u01A2]/g},
            {'base':'OO','letters':/[\uA74E]/g},
            {'base':'OU','letters':/[\u0222]/g},
            {'base':'P', 'letters':/[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g},
            {'base':'Q', 'letters':/[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g},
            {'base':'R', 'letters':/[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g},
            {'base':'S', 'letters':/[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g},
            {'base':'T', 'letters':/[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g},
            {'base':'TZ','letters':/[\uA728]/g},
            {'base':'U', 'letters':/[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g},
            {'base':'UE','letters':/[\u00DC]/g},
            {'base':'V', 'letters':/[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g},
            {'base':'VY','letters':/[\uA760]/g},
            {'base':'W', 'letters':/[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g},
            {'base':'X', 'letters':/[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g},
            {'base':'Y', 'letters':/[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g},
            {'base':'Z', 'letters':/[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g},
            {'base':'a', 'letters':/[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g},
            {'base':'aa','letters':/[\uA733]/g},
            {'base':'ae','letters':/[\u00E4\u00E6\u01FD\u01E3]/g},
            {'base':'ao','letters':/[\uA735]/g},
            {'base':'au','letters':/[\uA737]/g},
            {'base':'av','letters':/[\uA739\uA73B]/g},
            {'base':'ay','letters':/[\uA73D]/g},
            {'base':'b', 'letters':/[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g},
            {'base':'c', 'letters':/[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g},
            {'base':'d', 'letters':/[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g},
            {'base':'dz','letters':/[\u01F3\u01C6]/g},
            {'base':'e', 'letters':/[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g},
            {'base':'f', 'letters':/[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g},
            {'base':'g', 'letters':/[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g},
            {'base':'h', 'letters':/[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g},
            {'base':'hv','letters':/[\u0195]/g},
            {'base':'i', 'letters':/[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g},
            {'base':'j', 'letters':/[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g},
            {'base':'k', 'letters':/[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g},
            {'base':'l', 'letters':/[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g},
            {'base':'lj','letters':/[\u01C9]/g},
            {'base':'m', 'letters':/[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g},
            {'base':'n', 'letters':/[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g},
            {'base':'nj','letters':/[\u01CC]/g},
            {'base':'o', 'letters':/[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g},
            {'base':'oe','letters': /[\u00F6\u0153]/g},
            {'base':'oi','letters':/[\u01A3]/g},
            {'base':'ou','letters':/[\u0223]/g},
            {'base':'oo','letters':/[\uA74F]/g},
            {'base':'p','letters':/[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g},
            {'base':'q','letters':/[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g},
            {'base':'r','letters':/[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g},
            {'base':'s','letters':/[\u0073\u24E2\uFF53\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g},
            {'base':'ss','letters':/[\u00DF]/g},
            {'base':'t','letters':/[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g},
            {'base':'tz','letters':/[\uA729]/g},
            {'base':'u','letters':/[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g},
            {'base':'ue','letters':/[\u00FC]/g},
            {'base':'v','letters':/[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g},
            {'base':'vy','letters':/[\uA761]/g},
            {'base':'w','letters':/[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g},
            {'base':'x','letters':/[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g},
            {'base':'y','letters':/[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g},
            {'base':'z','letters':/[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g}
        ];

        var permalink = input;
        for(var i=0; i<diacriticsMap.length; i++) {
            permalink = permalink.replace(diacriticsMap[i].letters, diacriticsMap[i].base);
        }

        return permalink;
    },

    generateSlugByTitle: function(slug, title)
    {
        if( !title.length || !slug.length )
        {
            return false;
        }

        var generatedSlug = general_js.generateSlug(title.val());

        slug.val(generatedSlug);
    },

    generateSlugByTitleEventListener: function()
    {
        var $title = $('#title');
        var $slug = $('#slug');

       $('#app').on('click', '.generate-slug-button', function() {
          general_js.generateSlugByTitle($slug, $title);
       });
    },

    lazyItems: function() {
        window.lazyItem = null;

        window.lazyItemRegister = function () {
            if (lazyItem) {
                lazyItem.destroy();
            }

            window.lazyItem = $(document).find('.lazy').lazy({
                chainable: false,
                bind: "event",
                scrollDirection: 'vertical',
                effect: 'fadeIn',
                visibleOnly: true,
                afterLoad: function (element) {
                    element.removeClass('lazy');
                }
            });
        };

        window.lazyItemRegister();
    },

    disableTabAnchorJump: function() {
        $('#app').on('click', '.nav-tabs li a', function (e) {
            e.preventDefault();
            $(this).tab('show');
        });
    }
};

window.Laravel.helpers = {};
window.Laravel.helpers['showLoader'] = general_js.showLoader;
window.Laravel.helpers['hideLoader'] = general_js.hideLoader;
window.Laravel.helpers['flashAlert'] = general_js.flashAlert;
window.Laravel.helpers['redrawDatatableToFullwidth'] = general_js.redrawDatatableToFullwidth;
window.Laravel.helpers['generateSlug'] = general_js.generateSlug;

$(document).ready(function () {
    //general_js.aosInit();
    general_js.flashAlert();
    general_js.checkRowDelete();
    general_js.sidebarToggleSetting();
    general_js.sidebarMinimizerSetting();
    general_js.asideToggleSetting();
    general_js.initSiteSettings();
    general_js.ajaxForm();
    general_js.select2();
    general_js.confirmAction();
    general_js.allowNumericWithDecimal();
    general_js.allowNumericWithoutDecimal();
    general_js.tooltip();
    general_js.tablesorter();
    //general_js.disableLinks();
    general_js.matchHeightInit();
    //general_js.resizeSelect2();
    //general_js.mainNavigation();
    general_js.cardActions();
    general_js.disableMovingToTop();
    general_js.tabHashNavigation();
    general_js.inputSlugListener();
    general_js.preSelectTab();
    general_js.lazyItems();
    general_js.disableTabAnchorJump();
    general_js.generateSlugByTitleEventListener();
    //general_js.resizeBroadcast();
    //$(window).on('resize', general_js.resizeSelect2);
});
